@import "@fontsource/poppins/300.css";
@import "@fontsource/poppins/400.css";
@import "@fontsource/poppins/500.css";
@import "@fontsource/poppins/600.css";
@import "@fontsource/poppins/700.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins", sans-erif;
}

.app-sidebar {
  transform: translate3d(-120%, 0, 0);
  transition: 0.3s ease-out;
}

.app-sidebar.visible {
  transform: translate3d(0, 0, 0);
}

.app-sidebar-overlay {
  display: none;
}

.app-sidebar-overlay.visible {
  display: block;
}
